import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "query-string";
import LoginTokenPage from "./LoginTokenPage";
import LoginModalPage from "../LoginModalPage";
import { useAppSelector } from "../../hooks";
import { routes } from "../../routes";

const TOKEN_KEY = "token";

export const LoginPage = () => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const query = qs.parse(useLocation().search);
  const token = query[TOKEN_KEY];

  if (token) {
    return <LoginTokenPage token={String(token)} />;
  }

  // this needs to come after the first if statement because when the LoginTokenPage
  // succeeds, it sets isLoggedIn at true which would trigger this statement and
  // redirect to the home page whereas we need to redirect to the AccountPage
  if (isLoggedIn) {
    return <Redirect to={routes.home} />;
  }

  return <LoginModalPage />;
};
