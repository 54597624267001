import { AxiosInstance, AxiosRequestConfig } from "axios";
import type { Token } from "./dtos";
import { env } from "../utils";

export type LoginProvider = "keycloak" | "google" | "facebook" | "apple" | "";

export const login = (provider: LoginProvider) => {
  window.location.href = `${env.api.baseUri}/oauth2/authorization/${provider}`;
};

export const logout = () => {
  return async (axios: AxiosInstance) => {
    return axios.post("/logout");
  };
};

export const getToken = (loginToken: string) => {
  return async (axios: AxiosInstance) => {
    return axios.get<Token>("/token", withToken(loginToken));
  };
};

export const refreshToken = (refreshToken: string) => {
  return async (axios: AxiosInstance) => {
    return axios.get<Token>("/token/refresh", withToken(refreshToken));
  };
};

function withToken(token: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}
