import React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { api, CreateCheckout } from "../../../../../api";
import { env } from "../../../../../utils/env";
import { useAxiosQuery } from "../../../../../hooks";

export const SubscribeButton = () => {
  const subscribeQuery = useAxiosQuery<CreateCheckout>();
  const onClick = () => {
    subscribeQuery.run(api.subscription.createCheckout());
  };

  if (subscribeQuery.succeeded) {
    window.location.href = subscribeQuery.data!.checkoutUri;
  }

  return (
    <LoadingButton loading={shouldDisplayLoading()} variant="outlined" onClick={onClick}>
      S'abonner
      <Box component="span" sx={{ ml: "0.5rem", fontSize: "0.75rem" }}>
        ({env.subscriptionMonthlyFee}$ / mois)
      </Box>
    </LoadingButton>
  );

  function shouldDisplayLoading() {
    return subscribeQuery.isLoading || subscribeQuery.succeeded;
  }
};
