import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";

import { env } from "./utils";
import { store } from "./store";
import { theme } from "./theme";
import App from "./components/App";
import { assets } from "./utils/assets";

axios.defaults.baseURL = env.api.baseUri;

ReactDOM.render(
  <>
    <Helmet>
      <link rel="icon" type="image/png" href={assets.icon} />
    </Helmet>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </>,
  document.getElementById("app")
);
