import { styled } from "@mui/system";
import MuiCard from "@mui/material/Card";

export const Card = styled(MuiCard)({
  margin: "2rem 0"
});

export const CardHeader = styled("div")({
  margin: "0 0 1.75rem 0"
});

export const CardTitle = styled("span")({
  fontSize: "1.4rem"
});
