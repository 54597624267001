import jwtDecode, { JwtPayload as BaseJwtPayload } from "jwt-decode";
import type { LoginProvider, JwtPayload, SubscriptionStatus } from "../api";
import { fromEpochSeconds } from "./date";

/* eslint camelcase: "off" */
type DecodeJwtPayload = BaseJwtPayload & {
  email?: string;
  first_name?: string;
  last_name?: string;
  auth_provider?: LoginProvider;
  is_in_stripe?: boolean;
  subscription_status?: SubscriptionStatus;
  trial_ends_at?: number;
  cancels_at_period_end?: boolean;
  member_since?: number;
  subscribed_until?: number;
};

export const decodeJwt = (jwt: string): JwtPayload => {
  const decoded = jwtDecode<DecodeJwtPayload>(jwt);

  return {
    email: decoded.email || "",
    firstName: decoded.first_name || "",
    lastName: decoded.last_name || "",
    authProvider: decoded.auth_provider || "",
    isInStripe: decoded.is_in_stripe || false,
    subscriptionStatus: decoded.subscription_status || "INACTIVE",
    trialEndsAt: decoded.trial_ends_at ? fromEpochSeconds(decoded.trial_ends_at) : new Date(0),
    cancelsAtPeriodEnd: Boolean(decoded.cancels_at_period_end),
    memberSince: decoded.member_since ? fromEpochSeconds(decoded.member_since) : new Date(0),
    subscribedUntil: decoded.subscribed_until ? fromEpochSeconds(decoded.subscribed_until) : new Date(0)
  };
};
