import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { Button } from "./LoginButton.styled";

interface LoginButtonProps {
  onClick: () => void;
  icon: IconDefinition;
  text: string;
  bgColor: string;
}

export const LoginButton = (props: LoginButtonProps) => {
  return (
    <Button
      onClick={props.onClick}
      bgColor={props.bgColor}
      variant="contained"
      startIcon={<FontAwesomeIcon icon={props.icon} />}
    >
      {props.text}
    </Button>
  );
};
