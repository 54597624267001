import React from "react";
import Grid from "@mui/material/Grid";
import SubscribeButton from "../SubscribeButton";

export const InactiveCardContent = () => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>Vous n'avez pas accès à l'application.</Grid>
      <Grid item>Vous pouvez vous abonner pour profiter de l'application.</Grid>
      <Grid item>
        <SubscribeButton />
      </Grid>
    </Grid>
  );
};
