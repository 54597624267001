import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginProvider, Token } from "../../api";
import { localStorage, decodeJwt } from "../../utils";

interface UserState {
  idToken: string;
  refreshToken: string;
  loginProvider: LoginProvider;
  isLoggedIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  memberSince: Date;
}

const initialState = (): UserState => ({
  idToken: "",
  refreshToken: localStorage.refreshToken.get(""),
  loginProvider: localStorage.loginProvider.get(""),
  isLoggedIn: false,
  firstName: "",
  lastName: "",
  email: "",
  memberSince: new Date(0)
});

export const userSlice = createSlice({
  name: "user",
  initialState: initialState(),
  reducers: {
    login: (state, action: PayloadAction<Token>) => {
      const decodedJwt = decodeJwt(action.payload.idToken);
      localStorage.refreshToken.set(action.payload.refreshToken);
      localStorage.loginProvider.set(decodedJwt.authProvider);

      return {
        ...state,
        idToken: action.payload.idToken,
        refreshToken: action.payload.refreshToken,
        isLoggedIn: true,
        loginProvider: decodedJwt.authProvider,
        firstName: decodedJwt.firstName,
        lastName: decodedJwt.lastName,
        email: decodedJwt.email,
        memberSince: decodedJwt.memberSince
      };
    },
    logout: () => {
      localStorage.refreshToken.remove();

      return initialState();
    }
  }
});

export const { login, logout } = userSlice.actions;
export const reducer = userSlice.reducer;
