import React from "react";
import FeatureSection from "./FeatureSection";
import HeadSection from "./HeadSection";
import StatsSection from "./StatsSection";
import QuoteSection from "./QuoteSection";
import { HeadContainer, FeatureContainer, QuoteContainer, StatsContainer } from "./HomePage.styled";

export const HomePage = () => {
  return (
    <>
      <HeadContainer>
        <HeadSection />
      </HeadContainer>
      <FeatureContainer>
        <FeatureSection />
      </FeatureContainer>
      <QuoteContainer>
        <QuoteSection />
      </QuoteContainer>
      <StatsContainer>
        <StatsSection />
      </StatsContainer>
    </>
  );
};
