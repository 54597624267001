import React from "react";
import Chip from "@mui/material/Chip";
import { useAppSelector } from "../../../../hooks";

export const HeaderChip = () => {
  const status = useAppSelector((s) => s.subscription.status);

  if (status === "TRIAL") {
    return <Chip size="small" color="primary" label={"Période d'essai"} />;
  }

  if (status === "ACTIVE") {
    return <Chip size="small" color="success" label="Actif" />;
  }

  if (status === "GRACE_PERIOD") {
    return <Chip size="small" color="warning" label="Période de grâce" />;
  }

  return <Chip size="small" color="error" label="Inactif" />;
};
