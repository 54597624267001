import React from "react";
import { useAppSelector } from "../../../../hooks";
import GracePeriodCardContent from "./GracePeriodCardContent";
import TrialCardContent from "./TrialCardContent";
import ActiveCardContent from "./ActiveCardContent";
import InactiveCardContent from "./InactiveCardContent";

export const SubscriptionCardContent = () => {
  const status = useAppSelector((s) => s.subscription.status);

  if (status === "GRACE_PERIOD") {
    return <GracePeriodCardContent />;
  }

  if (status === "TRIAL") {
    return <TrialCardContent />;
  }

  if (status === "ACTIVE") {
    return <ActiveCardContent />;
  }

  return <InactiveCardContent />;
};
