import { styled } from "@mui/material/styles";
import MuiTypo from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const Typography = styled(MuiTypo)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center"
  }
}));

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    justifyContent: "center"
  }
}));

export const HeaderImage = styled("img")(({ theme }) => ({
  width: "100%",
  background: radialGradient(theme.palette.primary.light),
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: "300px"
  }
}));

function radialGradient(color: string): string {
  return (
    `radial-gradient(ellipse at center, ` +
    `${color}3C 0%, ${color}3C 62%, ${color}3C 62%,rgba(0,0,0,0) 62%,rgba(0,0,0,0) 62%)`
  );
}
