import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../../../hooks";
import { dateAsReadableText } from "../../../../../utils";
import ManageSubscriptionButton from "../ManageSubscriptionButton";

export const ActiveCardContent = () => {
  const subscribedUntil = useAppSelector((s) => s.subscription.subscribedUntil);
  const cancelsAtPeriodEnd = useAppSelector((s) => s.subscription.cancelsAtPeriodEnd);

  return (
    <Grid container direction="column" spacing={2}>
      {!cancelsAtPeriodEnd && renderAutomaticRenew()}
      {cancelsAtPeriodEnd && renderCancelled()}
      <Grid item>
        <ManageSubscriptionButton />
      </Grid>
    </Grid>
  );

  function renderAutomaticRenew() {
    return (
      <Grid item>
        Votre abonnement est actif et sera automatiquement renouvelé le <i>{dateAsReadableText(subscribedUntil)}</i>.
      </Grid>
    );
  }

  function renderCancelled() {
    return (
      <>
        <Grid item>
          Votre abonnement restera actif jusqu'au <i>{dateAsReadableText(subscribedUntil)}</i>.
        </Grid>
        <Grid item>
          <Typography color="error" variant="subtitle2">
            Attention! Après cette date, vous n'aurez plus accès à l'application. Il est encore temps de réactiver votre
            abonnement pour profiter de l'application.
          </Typography>
        </Grid>
      </>
    );
  }
};
