import React from "react";
import Grid from "@mui/material/Grid";
import MobileStoreButton from "../../common/MobileStoreButton";

export const MobileStoreLinks = () => {
  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <MobileStoreButton.Ios />
      </Grid>
      <Grid item>
        <MobileStoreButton.Android />
      </Grid>
    </Grid>
  );
};
