interface Home {
  header: string;
  stats: string;
}

interface PlatformProps {
  storeImageUrl: string;
  appLink: string;
}

interface MobileStores {
  android: PlatformProps;
  ios: PlatformProps;
}

interface Social {
  instagramUrl: string;
  facebookUrl: string;
}

interface Assets {
  home: Home;
  mobileStores: MobileStores;
  social: Social;
  icon: string;
  logo: string;
}

export const assets: Assets = {
  home: {
    header: "/assets/home/header.png",
    stats: "/assets/home/stats.png"
  },
  mobileStores: {
    android: {
      storeImageUrl: "https://play.google.com/intl/en_us/badges/static/images/badges/fr-ca_badge_web_generic.png",
      appLink: "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.kasbah.app"
    },
    ios: {
      storeImageUrl:
        "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-ca?size=250x83",
      appLink: "https://apps.apple.com/ca/app/kasba/id1565889973"
    }
  },
  social: {
    facebookUrl: "https://www.facebook.com/kasbaapp",
    instagramUrl: "https://www.instagram.com/kasbaapp"
  },
  icon: "/assets/logo/icon_32x32.png",
  logo: "/assets/logo/purple_no_bg_200x133.png"
};
