import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { api, CreatePortal } from "../../../../../api";
import { useAxiosQuery } from "../../../../../hooks";

export const ManageSubscriptionButton = () => {
  const portalQuery = useAxiosQuery<CreatePortal>();
  const onClick = () => {
    portalQuery.run(api.subscription.createPortal());
  };

  if (portalQuery.succeeded) {
    window.location.href = portalQuery.data!.portalUri;
  }

  return (
    <LoadingButton loading={shouldDisplayLoading()} variant="outlined" onClick={onClick}>
      Gérer mon abonnement
    </LoadingButton>
  );

  function shouldDisplayLoading() {
    return portalQuery.isLoading || portalQuery.succeeded;
  }
};
