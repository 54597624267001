import React from "react";
import Container from "@mui/material/Container";

export interface CenteredPageContainerProps {
  children: React.ReactChild | React.ReactChild[];
}

export const CenteredPageContainer = (props: CenteredPageContainerProps) => (
  <Container
    maxWidth="sm"
    sx={{
      flexGrow: 1,
      pb: "5vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    {props.children}
  </Container>
);
