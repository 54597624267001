import React from "react";
import { DotsContainer, DotProgress } from "./DotsProgress.styled";

export const DotsProgress = () => (
  <DotsContainer>
    <DotProgress />
    <DotProgress />
    <DotProgress />
    <DotProgress />
  </DotsContainer>
);
