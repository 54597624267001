import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Error from "@mui/icons-material/Error";

const DEFAULT_ERROR_MESSAGE = "Une erreur inattendue s'est produite";

interface ErrorCardProps {
  message?: string;
}

export const ErrorCard = (props: ErrorCardProps) => (
  <Box
    component="div"
    sx={{
      display: "flex",
      mt: "2rem",
      justifyContent: "center"
    }}
  >
    <Box
      component={Paper}
      sx={{
        p: "1rem 4rem",
        fontSize: "1.5rem",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Box
        component={Error}
        color="error"
        sx={{
          mr: "1rem"
        }}
      />
      <Typography variant="h5" color="error">
        {props.message || DEFAULT_ERROR_MESSAGE}
      </Typography>
    </Box>
  </Box>
);
