import { styled } from "@mui/system";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export const StyledContainer = styled(Container)({
  fontFamily: "monospace",
  fontSize: "1.1em"
});

export const LastUpdateGrid = styled(Grid)({
  fontFamily: "monospace",
  fontSize: "0.8em"
});
