import { useEffect, useRef } from "react";
import axios from "axios";
import type { AxiosInstance } from "axios";
import { useAppSelector } from "../redux";

export const useAxios = () => {
  const axiosRef = useRef<AxiosInstance>();
  const idToken = useAppSelector((s) => s.user.idToken);
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  useEffect(() => {
    axiosRef.current = isLoggedIn ? axiosWithBearer(idToken) : defaultAxios();

    return () => {
      axiosRef.current = undefined;
    };
  }, [idToken, isLoggedIn]);

  return axiosRef;
};

function axiosWithBearer(token: string): AxiosInstance {
  return axios.create({
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

function defaultAxios(): AxiosInstance {
  return axios.create();
}
