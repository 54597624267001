import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const LogoLink = styled(Link)({
  height: "100%"
});

export const Image = styled("img")({
  height: "inherit"
});
