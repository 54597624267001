import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

interface ButtonProps {
  bgColor: string;
}

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "bgColor"
})((props: ButtonProps) => ({
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 400,
  opacity: 0.95,
  backgroundColor: props.bgColor,
  minHeight: "3rem",
  width: "100%",
  "&:hover": {
    backgroundColor: props.bgColor,
    opacity: 1
  }
}));
