import React from "react";
import Box from "@mui/material/Box";
import { assets } from "../../../utils/assets";

interface MobileStoreButtonProps {
  width: number;
  height: number;
  imageUrl: string;
  appLink: string;
}

const MobileStoreButton = (props: MobileStoreButtonProps) => {
  const divStyles = {
    height: props.height,
    width: props.width
  };
  const linkStyles = {
    width: "100%",
    height: "100%",
    background: `url(${props.imageUrl}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    overflow: "hidden",
    textDecoration: "none"
  };

  return (
    <Box component="div" sx={divStyles}>
      <Box component="a" sx={linkStyles} href={props.appLink} />
    </Box>
  );
};

export const AndroidStoreButton = () => (
  <MobileStoreButton
    height={80}
    width={140}
    imageUrl={assets.mobileStores.android.storeImageUrl}
    appLink={assets.mobileStores.android.appLink}
  />
);

export const IosStoreButton = () => (
  <MobileStoreButton
    height={80}
    width={115}
    imageUrl={assets.mobileStores.ios.storeImageUrl}
    appLink={assets.mobileStores.ios.appLink}
  />
);
