import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";

export const MenuChip = styled(Chip)(({ theme }) => ({
  padding: "0 0.25rem",
  margin: "0 0.5rem",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark
  }
}));
