import React from "react";
import { useHistory } from "react-router";
import Hidden from "@mui/material/Hidden";
import { routes } from "../../../../routes";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

interface LoggedInMenuProps {
  onLogoutClick: () => void;
}

export const LoggedInMenu = (props: LoggedInMenuProps) => {
  const history = useHistory();
  const onAccountClick = () => history.push(routes.account);

  return (
    <>
      <Hidden smDown>
        <DesktopMenu onAccountClick={onAccountClick} onLogoutClick={props.onLogoutClick} />
      </Hidden>
      <Hidden smUp>
        <MobileMenu onAccountClick={onAccountClick} onLogoutClick={props.onLogoutClick} />
      </Hidden>
    </>
  );
};
