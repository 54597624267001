export interface Routes {
  home: string;
  login: string;
  mobileLogin: string;
  account: string;
  legal: string;
  privacyPolicy: string;
  termsOfServices: string;
}

export const routes: Routes = {
  home: "/",
  login: "/login",
  mobileLogin: "/mobile-login",
  account: "/account",
  legal: "/legal",
  privacyPolicy: "/legal/privacy",
  termsOfServices: "/legal/tos"
};
