import React from "react";
import Button from "@mui/material/Button";
import LoginModal from "../../../common/LoginModal";
import { useAppSelector, useModalState } from "../../../../hooks";

export const AnonymousMenu = () => {
  const modal = useModalState();
  const loginProvider = useAppSelector((s) => s.user.loginProvider);

  return (
    <div>
      <LoginModal open={modal.isOpen} onClose={modal.handleClose} loginProvider={loginProvider} />
      <Button variant="contained" onClick={modal.handleOpen} sx={{ padding: "0.75rem" }}>
        Connexion
      </Button>
    </div>
  );
};
