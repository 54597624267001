import React from "react";
import Box from "@mui/system/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/ExitToApp";

interface Props {
  onAccountClick: () => void;
  onLogoutClick: () => void;
}

export const MobileMenu = (props: Props) => (
  <Box display="flex" alignItems="center">
    <AccountCircle color="primary" fontSize="large" onClick={props.onAccountClick} sx={{ mr: "1.5rem" }} />
    <LogoutIcon color="primary" fontSize="large" onClick={props.onLogoutClick} sx={{ mr: "0.5rem" }} />
  </Box>
);
