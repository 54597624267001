import React from "react";
import { env } from "../../../utils";

export interface DevOnlyProps {
  children: React.ReactChild | React.ReactChild[];
}

export const DevOnly = (props: DevOnlyProps) => {
  if (env.isProd) {
    return <></>;
  }

  return <>{props.children}</>;
};
