import React from "react";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography, ContainerGrid, HeaderImage } from "./HeadSection.styled";
import { env } from "../../../utils/env";
import LoginModal from "../../common/LoginModal";
import MobileStoreButton from "../../common/MobileStoreButton";
import { assets } from "../../../utils/assets";
import { useAppSelector, useModalState } from "../../../hooks";

export const HeadSection = () => {
  const loginModal = useModalState();
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const loginProvider = useAppSelector((s) => s.user.loginProvider);

  return (
    <Grid container spacing={4}>
      <LoginModal open={loginModal.isOpen} onClose={loginModal.handleClose} loginProvider={loginProvider} />
      <ContainerGrid container item xs={12} md={6} alignContent="space-around" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Simplifiez votre quotidien!</Typography>
        </Grid>
        <Hidden mdUp>
          <Grid item md={6}>
            <HeaderImage src={assets.home.header} />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography variant="h5">
            Kasba est la plateforme pour vous libérer de vos responsabilités administratives.
          </Typography>
        </Grid>
        {!isLoggedIn && (
          <Grid item xs={12}>
            <Typography variant="h6">
              Profitez de {env.nbOfTrialDays} jours d'essai gratuits sans engagement!
            </Typography>
          </Grid>
        )}
        <Hidden mdDown>{renderJoinUsSection("flex-start")}</Hidden>
        <Hidden mdUp>{renderJoinUsSection("center")}</Hidden>
      </ContainerGrid>
      <Hidden mdDown>
        <Grid item md={6}>
          <HeaderImage src={assets.home.header} />
        </Grid>
      </Hidden>
    </Grid>
  );

  function renderJoinUsSection(justifyContent: string) {
    return (
      <Grid container item xs={12} justifyContent={justifyContent}>
        {!isLoggedIn && (
          <Grid item>
            <Button size="large" variant="contained" sx={{ padding: "1.25rem" }} onClick={loginModal.handleOpen}>
              Rejoignez-nous
            </Button>
          </Grid>
        )}
        <Grid container item xs={12} justifyContent={justifyContent} spacing={1}>
          <Grid item>
            <MobileStoreButton.Ios />
          </Grid>
          <Grid item>
            <MobileStoreButton.Android />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
