import React from "react";
import IconButton from "@mui/material/IconButton";

interface FooterIconProps {
  children: React.ReactElement;
  linkUrl: string;
  ariaLabel: string;
}

export const FooterIcon = (props: FooterIconProps) => (
  <IconButton aria-label={props.ariaLabel} href={props.linkUrl} sx={{ color: "white" }}>
    {props.children}
  </IconButton>
);
