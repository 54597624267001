import { fakeFailure, fakeSuccess } from "./fake";
import { login, logout, getToken, refreshToken } from "./auth";
import { createCheckout, createPortal } from "./subscription";
import { deleteMe } from "./me";

export type { Token, JwtPayload, SubscriptionStatus, CreateCheckout, CreatePortal } from "./dtos";
export type { LoginProvider } from "./auth";

export const api = {
  subscription: {
    createCheckout,
    createPortal
  },
  auth: {
    login,
    logout,
    getToken,
    refreshToken
  },
  account: {
    delete: deleteMe
  },
  fake: {
    success: fakeSuccess,
    failure: fakeFailure
  }
};
