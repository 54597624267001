import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import PageContainer from "../common/PageContainer";
import { routes } from "../../routes";

export const LegalPage = () => {
  return (
    <PageContainer>
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid item>
          <Link to={routes.privacyPolicy}>Politique de confidentialité</Link>
        </Grid>
        <Grid item>
          <Link to={routes.termsOfServices}>Conditions d'utilisation</Link>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
