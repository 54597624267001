import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { Card, CardTitle, CardHeader } from "../AccountPage.styled";
import HeaderChip from "./HeaderChip";
import SubscriptionCardContent from "./SubscriptionCardContent";

const moreInfos: string[] = [
  "C'est une dépense déductible d'impôts",
  "Facturé à chaque mois à partir de la date de fin de la période d'essai",
  "Accès complet à l'application et à ses mises à jour",
  "Accès au support (email, téléphone, messenger)",
  "Vous pouvez annuler votre abonnement en tout temps"
];

export const SubscriptionCard = () => {
  const [moreInfoOpen, setMoreInfoOpen] = React.useState<boolean>(false);
  const handleMoreInfoClick = () => setMoreInfoOpen((old) => !old);

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CardTitle>Abonnement</CardTitle>
            </Grid>
            <Grid item>
              <HeaderChip />
            </Grid>
          </Grid>
        </CardHeader>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <SubscriptionCardContent />
          </Grid>
          <Grid item>
            <Link sx={{ cursor: "pointer", fontSize: "0.9rem" }} onClick={handleMoreInfoClick}>
              En savoir plus sur l'abonnement
              <IconButton size="small" disableRipple disableFocusRipple>
                {moreInfoOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Collapse in={moreInfoOpen} unmountOnExit>
              <List sx={{ fontSize: "0.85rem" }} dense disablePadding>
                {moreInfos.map((text, i) => (
                  <ListItem key={i} disableGutters>
                    - {text}
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
