import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

export const HeadContainer = styled(Container)({
  margin: "4rem auto"
});

export const FeatureContainer = styled(Container)({
  marginBottom: "4rem"
});

export const QuoteContainer = styled("div")(({ theme }) => ({
  marginBottom: "6rem",
  backgroundColor: theme.palette.primary.main
}));

export const StatsContainer = styled(Container)({
  marginBottom: "6rem"
});
