import { styled, keyframes } from "@mui/system";

export const DotsContainer = styled("div")({
  textAlign: "center",
  width: "100%"
});

const dotKeyframes = keyframes({
  "0%, 20%, 80%, 100%": {
    opacity: 0.3
  },
  "50%": {
    opacity: 0.8
  }
});

export const DotProgress = styled("div")(({ theme }) => ({
  width: "0.75rem",
  height: "0.75rem",
  borderRadius: "0.75rem",
  margin: "0 0.35rem",
  animation: `${dotKeyframes} 2s infinite ease-in-out`,
  animationFillMode: "both",
  backgroundColor: theme.palette.primary.main,
  display: "inline-block",

  "&:nth-of-type(2)": {
    animationDelay: "0.5s"
  },
  "&:nth-of-type(3)": {
    animationDelay: "1s"
  },
  "&:nth-of-type(4)": {
    animationDelay: "1.5s"
  }
}));
