import React from "react";
import { Redirect } from "react-router-dom";
import { useAxiosEffect } from "../../../hooks";
import { api } from "../../../api";
import { login } from "../../../states/user";
import { routes } from "../../../routes";
import LoadingPage from "../../common/LoadingPage";

export interface LoginTokenPageProps {
  token: string;
}

export const LoginTokenPage = ({ token }: LoginTokenPageProps) => {
  const getToken = useAxiosEffect({
    toRun: api.auth.getToken(String(token)),
    action: login
  });

  if (getToken.succeeded) {
    return <Redirect to={routes.account} />;
  }

  if (getToken.failed) {
    return <Redirect to={routes.home} />;
  }

  return <LoadingPage />;
};
