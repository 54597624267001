import React from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useModalState } from "../../hooks";
import { LoginProvider } from "../../api";
import LoginModal from "../common/LoginModal";
import { Background } from "./LoginModalPage.styled";

const PROVIDER_KEY = "provider";

export const LoginModalPage = () => {
  const modal = useModalState(true);
  const query = qs.parse(useLocation().search);
  const provider = (query[PROVIDER_KEY] || "") as LoginProvider;

  return (
    <Background>
      <LoginModal open={modal.isOpen} loginProvider={provider} />
    </Background>
  );
};
