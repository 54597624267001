import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { ResponsiveCard } from "./FeatureItem.styled";

interface Props {
  title: string;
  description: string;
  icon: JSX.Element;
}

export const FeatureItem = (props: Props) => (
  <Grid item md={4} sm={6} xs={12}>
    <ResponsiveCard>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Avatar sx={{ bgcolor: "primary.main" }}>{props.icon}</Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">{props.title}</Typography>
        </Grid>
        <Grid item>
          <Typography paragraph sx={{ color: "gray" }}>
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </ResponsiveCard>
  </Grid>
);
