import { useState } from "react";

interface ModalState {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useModalState = (defaultState: boolean = false): ModalState => {
  const [isOpen, setOpen] = useState<boolean>(defaultState);

  return {
    isOpen,
    handleOpen: () => setOpen(true),
    handleClose: () => setOpen(false)
  };
};
