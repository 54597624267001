import { AxiosInstance } from "axios";
import { routes } from "../routes";
import { CreateCheckout as CheckoutResponse, CreatePortal as PortalResponse } from "./dtos";

interface CreateCheckoutRequest {
  successUri: string;
  cancelUri: string;
}

interface CreatePortalRequest {
  returnUri: string;
}

const createCheckoutRequest: CreateCheckoutRequest = {
  successUri: `${window.origin}${routes.account}`,
  cancelUri: `${window.origin}${routes.account}`
};

const createPortalRequest: CreatePortalRequest = {
  returnUri: `${window.origin}${routes.account}`
};

export const createCheckout = () => {
  return async (axios: AxiosInstance) => {
    return axios.post<CheckoutResponse>("/checkout", createCheckoutRequest);
  };
};

export const createPortal = () => {
  return async (axios: AxiosInstance) => {
    return axios.post<PortalResponse>("/checkout/portal", createPortalRequest);
  };
};
