import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../../../hooks";
import { dateAsReadableText } from "../../../../../utils";

export const GracePeriodCardContent = () => {
  const subscribedUntil = useAppSelector((s) => s.subscription.subscribedUntil);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography color="error">
          Un problème s'est produit lors de la détection de votre abonnement. Vous avez quand même accès à l'application
          jusqu'au <i>{dateAsReadableText(subscribedUntil)}</i>. Nous sommes désolés du problème technique.
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="error">Rafraîchir la page peut règler le problème.</Typography>
      </Grid>
    </Grid>
  );
};
