import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

export interface QuoteItemProps {
  quote: string;
  name: string;
}

export const QuoteItem = (props: QuoteItemProps) => (
  <Grid
    container
    item
    xs={6}
    md={4}
    sx={{ px: "2rem" }}
    direction="column"
    alignItems="flex-end"
    justifyContent="space-between"
  >
    <Grid item sx={{ mb: "1rem" }}>
      <Typography variant="h5" color="white">
        <FontAwesomeIcon icon={faQuoteLeft} size="xs" />
        &nbsp;&nbsp;
        {props.quote}
        &nbsp;&nbsp;
        <FontAwesomeIcon icon={faQuoteRight} size="xs" />
      </Typography>
    </Grid>
    <Grid item>
      <Typography color="white">{props.name}</Typography>
    </Grid>
  </Grid>
);
