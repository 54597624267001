import React from "react";
import Grid from "@mui/material/Grid";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import BallotIcon from "@mui/icons-material/Ballot";
import HistoryIcon from "@mui/icons-material/History";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import EditIcon from "@mui/icons-material/Edit";
import FeatureItem from "./FeatureItem";

const features = [
  {
    title: "Génération de formulaires",
    desc: "Les formulaires se génèrent et se remplissent pour vous. Vous n'avez plus qu'à signer!",
    icon: <DescriptionIcon />
  },
  {
    title: "Signature éléctronique",
    desc: "Fini la paperasse! Les signatures de formulaires se font directement dans l'application!",
    icon: <EditIcon />
  },
  {
    title: "Envoi éléctronique",
    desc: "L'envoi des formulaires administratifs se fait maintenant en un simple clic.",
    icon: <EmailOutlined />
  },
  {
    title: "Prise de présence",
    desc: "La prise de présence quotidienne des enfants se fait à même l'application!",
    icon: <BallotIcon />
  },
  {
    title: "Archivage de présences",
    desc: "Grâce à la numérisation des présences, vous pouvez accéder facilement à vos données antérieures.",
    icon: <HistoryIcon />
  },
  {
    title: "Envoi des formulaires aux parents",
    desc: "Les fiches d'assiduité peuvent être envoyées aux parents concernés sans difficulté.",
    icon: <FamilyRestroomIcon />
  }
];

export const FeatureSection = () => (
  <Grid container spacing={4} sx={{ padding: "2rem 0 2rem 0" }}>
    {features.map((feature, index) => (
      <FeatureItem key={index} title={feature.title} description={feature.desc} icon={feature.icon} />
    ))}
  </Grid>
);
