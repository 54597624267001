import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";
import { frCA } from "date-fns/locale";

export const fromEpochSeconds = (seconds: number) => addSeconds(new Date(0), seconds);

export const dateAsReadableText = (date: Date) =>
  format(date, "d MMMM y", {
    locale: frCA
  });

export const now = () => new Date();
