import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionStatus, Token } from "../../api";
import { decodeJwt } from "../../utils";
import { login, logout } from "../user";

interface SubscriptionState {
  status: SubscriptionStatus;
  isInStripe: boolean;
  trialEndsAt: Date;
  subscribedUntil: Date;
  cancelsAtPeriodEnd: boolean;
}

const initialState = (): SubscriptionState => ({
  status: "INACTIVE",
  isInStripe: false,
  trialEndsAt: new Date(0),
  subscribedUntil: new Date(0),
  cancelsAtPeriodEnd: false
});

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login, (state, action: PayloadAction<Token>) => {
        const decodedJwt = decodeJwt(action.payload.idToken);

        return {
          ...state,
          status: decodedJwt.subscriptionStatus,
          isInStripe: decodedJwt.isInStripe,
          trialEndsAt: decodedJwt.trialEndsAt,
          subscribedUntil: decodedJwt.subscribedUntil,
          cancelsAtPeriodEnd: decodedJwt.cancelsAtPeriodEnd
        };
      })
      .addCase(logout, () => initialState())
      .addDefaultCase((state) => state);
  }
});

export const reducer = subscriptionSlice.reducer;
