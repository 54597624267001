import React from "react";
import { useLocation } from "react-router";
import qs from "query-string";
import PageContainer from "../common/PageContainer";
import SubscriptionCard from "./SubscriptionCard";
import AccountCard from "./AccountCard";
import SubscriptionSucceededPage from "./SubscriptionSucceededPage";
import MobileStoreLinks from "./MobileStoreLinks";

const SUCCESS_KEY = "success";

export const AccountPage = () => {
  const query = qs.parse(useLocation().search);
  const successQuery = query[SUCCESS_KEY];

  if (successQuery) {
    return <SubscriptionSucceededPage error={successQuery === String(false)} />;
  }

  return (
    <PageContainer>
      <AccountCard />
      <SubscriptionCard />
      <MobileStoreLinks />
    </PageContainer>
  );
};
