import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../../../hooks";
import { dateAsReadableText } from "../../../../../utils";
import ManageSubscriptionButton from "../ManageSubscriptionButton";
import SubscribeButton from "../SubscribeButton";

export const TrialCardContent = () => {
  const trialEndsAt = useAppSelector((s) => s.subscription.trialEndsAt);
  const cancelsAtPeriodEnd = useAppSelector((s) => s.subscription.cancelsAtPeriodEnd);
  const isInStripe = useAppSelector((s) => s.subscription.isInStripe);

  return (
    <Grid container direction="column" spacing={2}>
      {isInStripe && cancelsAtPeriodEnd && renderStripeCancelled()}
      {isInStripe && !cancelsAtPeriodEnd && renderStripeSubscribed()}
      {!isInStripe && renderDefaultTrialState()}
    </Grid>
  );

  function renderStripeSubscribed() {
    return (
      <>
        <Grid item>
          Votre abonnement sera actif à la fin de la période d'essai, le <i>{dateAsReadableText(trialEndsAt)}</i>.
        </Grid>
        <Grid item>
          <ManageSubscriptionButton />
        </Grid>
      </>
    );
  }

  function renderStripeCancelled() {
    return (
      <>
        <Grid item>
          Votre période d'essai se termine le <i>{dateAsReadableText(trialEndsAt)}</i>.
        </Grid>
        <Grid item>
          <Typography color="error" variant="subtitle2">
            Nous voyons que vous avez un abonnement annulé. Il est encore temps de le réactiver pour profiter de
            l'application après votre période d'essai.
          </Typography>
        </Grid>
        <Grid item>
          <ManageSubscriptionButton />
        </Grid>
      </>
    );
  }

  function renderDefaultTrialState() {
    return (
      <>
        <Grid item>
          Votre période d'essai se termine le <i>{dateAsReadableText(trialEndsAt)}</i>.
        </Grid>
        <Grid item>
          <Typography color="error" variant="subtitle2">
            Abonnez-vous pour profiter de l'application après votre période d'essai.
          </Typography>
        </Grid>
        <Grid item>
          <SubscribeButton />
        </Grid>
      </>
    );
  }
};
