import React from "react";
import Box from "@mui/material/Box";
import CenteredPageContainer from "../CenteredPageContainer";
import KasbaLogo from "../KasbaLogo";
import DotsProgress from "./DotsProgress";

export const LoadingPage = () => (
  <CenteredPageContainer>
    <Box
      sx={{
        height: "5rem",
        mb: "1rem"
      }}
    >
      <KasbaLogo />
    </Box>
    <DotsProgress />
  </CenteredPageContainer>
);
