import React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";

const helpEmail: string = "aide@kasba.app";
const phoneNumber: string = "(438) 357-4741";

export const FooterPhysicalContact = () => (
  <>
    <Grid container item alignItems="center">
      <Avatar sx={{ bgcolor: "white", color: "primary.main", width: 36, height: 36 }}>
        <Phone fontSize="small" />
      </Avatar>
      <Typography variant="subtitle2" ml={2}>
        {phoneNumber}
      </Typography>
    </Grid>
    <Grid container alignItems="center" item onClick={() => window.open(`mailto:${helpEmail}`)}>
      <Avatar sx={{ bgcolor: "white", color: "primary.main", width: 36, height: 36 }}>
        <Email fontSize="small" />
      </Avatar>
      <Typography variant="subtitle2" sx={{ textDecoration: "underline", ml: 2 }}>
        {helpEmail}
      </Typography>
    </Grid>
  </>
);
