import React from "react";
import Grid from "@mui/material/Grid";
import FooterPhysicalContact from "./FooterPhysicalContact";
import FooterAbout from "./FooterAbout";
import { FooterContainer } from "./Footer.styled";

export const Footer = () => (
  <FooterContainer>
    <Grid container spacing={5} justifyContent="center">
      <Grid container item xs={12} md={4} direction="column" spacing={2}>
        <FooterPhysicalContact />
      </Grid>
      <Grid container item xs={12} md={4}>
        <FooterAbout />
      </Grid>
    </Grid>
  </FooterContainer>
);
