import React from "react";
import Grid from "@mui/material/Grid";
import { StyledContainer, LastUpdateGrid } from "./LegalContainer.styled";

export interface LegalContainerProps {
  updatedAt: string;
  paragraphs: string[];
}

export const LegalContainer = (props: LegalContainerProps) => (
  <StyledContainer maxWidth="md" sx={{ m: "6rem auto" }}>
    <Grid container spacing={3} direction="column">
      <LastUpdateGrid item>Dernière mise à jour: {props.updatedAt}</LastUpdateGrid>
      {props.paragraphs.map((paragraph, i) => (
        <Grid key={i} item>
          {paragraph}
        </Grid>
      ))}
    </Grid>
  </StyledContainer>
);
