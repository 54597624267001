import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Account from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import { faKaggle, faFacebookF, faGoogle, faApple } from "@fortawesome/free-brands-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import { api, LoginProvider } from "../../../api";
import DevOnly from "../../common/DevOnly";
import LoginButton from "./LoginButton";

const KEYCLOAK_COLOR = "rgb(199,21,133)";
const FACEBOOK_COLOR = "rgb(59, 89, 152)";
const GOOGLE_COLOR = "rgb(221, 75, 57)";
const APPLE_COLOR = "rgb(50, 50, 50)";

interface Props {
  open: boolean;
  onClose?: () => void;
  loginProvider: LoginProvider;
}

const ALL_PROVIDERS: LoginProvider[] = ["keycloak", "apple", "google", "facebook"];

export const LoginModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const closeable = Boolean(props.onClose);
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal open={props.open} onClose={onClose} hideBackdrop={!closeable}>
      <Card
        variant="outlined"
        sx={{
          userSelect: "none",
          p: "3rem 2rem",
          position: "absolute",
          top: "45%",
          left: "calc(50% - 16px)",
          transform: "translate(-50%, -50%)",
          margin: "0 16px",
          width: "min(350px, 90vw)"
        }}
      >
        <Grid container direction="column" alignItems="center">
          {closeable && (
            <Grid item alignSelf="flex-end" sx={{ cursor: "pointer" }} mt="-2rem">
              <CloseIcon onClick={onClose} color="primary" />
            </Grid>
          )}
          <Grid item>
            <Account sx={{ bgcolor: "primary.main" }}>
              <LockOpenSharpIcon />
            </Account>
          </Grid>
          <Grid item sx={{ mb: "3rem" }}>
            <Typography variant="h4" color="primary">
              Connexion
            </Typography>
          </Grid>
          {isLoading && renderLoading()}
          {!isLoading && (
            <Grid item container spacing={2} direction="column" alignItems="stretch">
              {renderLoginOptions()}
            </Grid>
          )}
        </Grid>
      </Card>
    </Modal>
  );

  function renderLoading() {
    return (
      <Grid item>
        <CircularProgress />
      </Grid>
    );
  }

  function renderLoginOptions() {
    if (shouldRenderAllProviders()) {
      return renderLoginButtons(ALL_PROVIDERS);
    }

    return (
      <>
        <Grid item alignSelf="center">
          Reconnectez-vous
        </Grid>
        {renderLoginButtons([props.loginProvider])}
        <Grid item sx={{ mt: "1.5rem", textAlign: "center" }}>
          Utilisez un nouveau moyen de connexion
        </Grid>
        {renderLoginButtons(listOtherLoginProviders())}
      </>
    );
  }

  function renderLoginButtons(providersToRender: LoginProvider[]) {
    return (
      <>
        {providersToRender.includes("keycloak") && (
          <DevOnly>
            <Grid item>
              <LoginButton
                onClick={makeOnClick("keycloak")}
                bgColor={KEYCLOAK_COLOR}
                icon={faKaggle}
                text="Se connecter avec Keycloak"
              />
            </Grid>
          </DevOnly>
        )}
        {providersToRender.includes("google") && (
          <Grid item>
            <LoginButton
              onClick={makeOnClick("google")}
              bgColor={GOOGLE_COLOR}
              icon={faGoogle}
              text="Se connecter avec Google"
            />
          </Grid>
        )}
        {providersToRender.includes("facebook") && (
          <Grid item>
            <LoginButton
              onClick={makeOnClick("facebook")}
              bgColor={FACEBOOK_COLOR}
              icon={faFacebookF}
              text="Se connecter avec Facebook"
            />
          </Grid>
        )}
        {providersToRender.includes("apple") && (
          <Grid item>
            <LoginButton
              onClick={makeOnClick("apple")}
              bgColor={APPLE_COLOR}
              icon={faApple}
              text="Se connecter avec Apple"
            />
          </Grid>
        )}
      </>
    );
  }

  function makeOnClick(provider: LoginProvider): () => void {
    return () => {
      setIsLoading(true);
      api.auth.login(provider);
    };
  }

  function shouldRenderAllProviders() {
    return props.loginProvider === "";
  }

  function listOtherLoginProviders() {
    return ALL_PROVIDERS.filter((provider) => provider !== props.loginProvider);
  }
};
