import type { AxiosResponse } from "axios";

export const fakeSuccess = <T>(returns: T) => {
  return () => Promise.resolve(makeAxiosResponse(returns, 200));
};

export const fakeFailure = (status: number) => {
  return () => Promise.reject(makeAxiosResponse({}, status));
};

function makeAxiosResponse<T>(data: T, status: number): AxiosResponse<T> {
  return {
    data,
    status,
    statusText: "update me when needed",
    headers: {},
    config: {}
  };
}
