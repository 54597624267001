import React from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import { useAppSelector } from "../../../../../hooks";
import { MenuChip } from "./DesktopMenu.styled";

interface Props {
  onAccountClick: () => void;
  onLogoutClick: () => void;
}

export const DesktopMenu = (props: Props) => {
  const userFirstName = useAppSelector((s) => s.user.firstName);

  return (
    <div>
      <MenuChip color="primary" onClick={props.onAccountClick} icon={<AccountCircle />} label={userFirstName} />
      <MenuChip color="primary" onClick={props.onLogoutClick} icon={<LogoutIcon />} label={"Se déconnecter"} />
    </div>
  );
};
