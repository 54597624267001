import { LoginProvider } from "../api";

const ls = window.localStorage;

interface LocalStorageItem<T> {
  set: (value: T) => void;
  get: (defaultValue: T) => T;
  remove: () => void;
}

export interface LocalStorage {
  refreshToken: LocalStorageItem<string>;
  loginProvider: LocalStorageItem<LoginProvider>;
}

export const localStorage: LocalStorage = {
  refreshToken: generateLocalStorageItem("refresh_token"),
  loginProvider: generateLocalStorageItem("login_provider")
};

function generateLocalStorageItem<T>(key: string): LocalStorageItem<T> {
  return {
    set: (value: T) => ls.setItem(key, String(value)),
    get: (defaultValue: T) => (ls.getItem(key) || defaultValue) as T,
    remove: () => ls.removeItem(key)
  };
}
