import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { routes } from "../../../../routes";
import { assets } from "../../../../utils/assets";
import FooterIcon from "./FooterIcon";
import { LegalLink } from "./FooterAbout.styled";

export const FooterAbout = () => (
  <Grid item>
    <Typography paragraph>À propos de nous :</Typography>
    <Typography variant="subtitle2" paragraph>
      Kasba a pour mission d'aider le plus de RSE possible afin de garantir un meilleur environnement pour les jeunes
      enfants du Québec.
    </Typography>
    <Box>
      <span>
        <FooterIcon ariaLabel="Facebook" linkUrl={assets.social.facebookUrl}>
          <FacebookIcon fontSize="medium" />
        </FooterIcon>
      </span>
      <span>
        <FooterIcon ariaLabel="Instagram" linkUrl={assets.social.instagramUrl}>
          <InstagramIcon fontSize="medium" />
        </FooterIcon>
      </span>
      <span>
        <FooterIcon ariaLabel="Google" linkUrl={assets.mobileStores.android.appLink}>
          <GoogleIcon fontSize="medium" />
        </FooterIcon>
      </span>
      <span>
        <FooterIcon ariaLabel="Apple" linkUrl={assets.mobileStores.ios.appLink}>
          <AppleIcon fontSize="medium" />
        </FooterIcon>
      </span>
      <LegalLink to={routes.legal}>Légal</LegalLink>
    </Box>
  </Grid>
);
