import { useState, useCallback, useEffect, DependencyList } from "react";
import { PayloadAction } from "@reduxjs/toolkit";
import type { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import { useAxios } from "./axios";
import { useAppDispatch } from "../redux";

type AxiosFn<T> = (axios: AxiosInstance) => Promise<AxiosResponse<T>>;
type ActionFn<T> = (t: T) => PayloadAction<T>;

interface AxiosQueryEffectProps<T> {
  toRun: AxiosFn<T>;
  action?: ActionFn<T>;
  deps?: DependencyList;
}

interface AxiosRequestState<T> {
  isLoading: boolean;
  failed: boolean;
  succeeded: boolean;
  statusCode?: StatusCodes;
  data?: T;
}

type AxiosQueryResult<T> = AxiosRequestState<T> & {
  run: (fn: AxiosFn<T>) => Promise<void>;
};

export const useAxiosQuery = <T>(action?: ActionFn<T>): AxiosQueryResult<T> => {
  return useQuery(false, action);
};

export const useAxiosEffect = <T>(q: AxiosQueryEffectProps<T>): AxiosRequestState<T> => {
  const query = useQuery(true, q.action);

  useEffect(() => {
    query.run(q.toRun);
  }, [...(q.deps || [])]);

  return query;
};

export const useQuery = <T>(isLoading: boolean, action?: ActionFn<T>): AxiosQueryResult<T> => {
  const axiosRef = useAxios();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<AxiosRequestState<T>>({
    isLoading,
    failed: false,
    succeeded: false
  });

  const run = useCallback(
    async (fn: AxiosFn<T>) => {
      setState({
        isLoading: true,
        failed: false,
        succeeded: false,
        statusCode: undefined
      });

      try {
        if (!axiosRef.current) return;
        const result = await fn(axiosRef.current);

        setState({
          isLoading: false,
          failed: false,
          succeeded: true,
          statusCode: result.status,
          data: result.data
        });
        // The dispatch must be after the setState to avoid memory leaks
        action && dispatch(action(result.data));
      } catch (error) {
        setState({
          isLoading: false,
          failed: true,
          succeeded: false,
          statusCode: (error as AxiosError).response?.status || StatusCodes.INTERNAL_SERVER_ERROR
        });
      }
    },
    [axiosRef, dispatch]
  );

  return { run, ...state };
};
