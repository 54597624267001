import React from "react";
import { assets } from "../../../utils/assets";
import { LogoLink, Image } from "./KasbaLogo.styled";

interface KasbaLogoProps {
  to?: string;
}

export const KasbaLogo = (props: KasbaLogoProps) => {
  if (props.to) {
    return (
      <LogoLink to={props.to}>
        <Image src={assets.logo} />
      </LogoLink>
    );
  }

  return <Image src={assets.logo} />;
};
