import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { assets } from "../../../utils/assets";

export const StatsSection = () => {
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={6} spacing={4} direction="column">
        <Grid item>
          <Typography variant="h4">L'application par exellence pour le milieu familial</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" color="primary" mb={1}>
              95%
            </Typography>
            <Typography>
              <FontAwesomeIcon color="orange" icon={faClock} />
              &nbsp; 95% est le temps moyen économisé par nos utilisateurs lors de leurs tâches administratives.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" color="primary" mb={1}>
              125+
            </Typography>
            <Typography>
              <FontAwesomeIcon color="green" icon={faLeaf} />
              &nbsp; 125 feuilles de papier par année en moyenne sont économisées grâce à la signature et l'envoi
              éléctronique.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={6}>
          <Card>
            <CardMedia component="img" src={assets.home.stats} />
          </Card>
        </Grid>
      </Hidden>
    </Grid>
  );
};
