export const updatedAt = "3 mars 2022";

const title = "KASBA - POLITIQUE DE CONFIDENTIALITÉ";

const intro = `
  Cette Politique de Confidentialité décrit comment vos informations personnelles sont
  collectées, utilisées et partagées lorsque vous visitez ou faites un achat sur https://kasba.app (le "Site").
`;

const infoWeCollectTitle = "INFORMATIONS PERSONNELLES COLLECTÉES";
const infoWeCollect1 = `
  Lors d'une visite sur le Site, certaines informations sont automatiquement collectées à propos de votre appareil.
  Ces informations incluent votre navigateur web, adresse IP, fuseau horaire et certains cookies qui sont installés
  sur votre appareil. De plus, lorsque vous naviguez sur le Site, nous collectons de l'information à propos des pages
  web du Site que vous avez visité, quels sites web ou termes de recherche vous ont référé sur le Site.
  Nous appelons ces informations automatiquement collectées de "l'Information sur l'Appareil".
`;
const infoWeCollect2 = `
  Nous collectons de l'Information sur l'Appareil en utilisant ces technologies:
  (1) Les "Fichier de journaux" tracent vos actions sur le Site et collectent des données incluant
  votre adresse IP, le type de navigateur web, votre fournisseur internet, les pages sur lesquelles vous êtes arrivé
  ou avez quitté le Site, la date et l'heure de votre navigation.
`;
const infoWeCollect3 = `
  De plus, lorsque vous faites ou essayez de faire une transaction d'abonnement sur le Site, Stripe 
  (un "Service de Paiement" tierce) est responsable de ces transactions. Pour parvenir à identifier une transaction,
  nous devons fournir au Service de Paiement certaines informations sur vous incluant votre nom, votre adresse 
  électronique et votre numéro de téléphone (si spécifier). Nous référons à ces informations par "Information d'Achat".
`;
const infoWeCollect4 = `
  Lorsque nous parlons "d'Informations Personnelles" dans cette Politique de Confidentialité, nous parlons à la fois de
  l'Information sur l'Appareil et de l'Information d'Achat.
`;

const usePersonalInfoTitle = "COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES ?";
const usePersonalInfo1 = `
  Nous utilisons l'Information d'Achat collectée pour vous permettre d'effectuer vos transactions. Étant donné que ces
  transactions sont effectuées sur le Service de Paiement, nous ne sommes pas en mesure de les utiliser. De plus, nous
  utilisons votre Information d'Achat pour: (1) Communiquez avec vous.
`;
const usePersonalInfo2 = `
  Nous utilisons l'Information sur l'Appareil pour nous aider à améliorer et optimiser le Site (par exemple, en générant
  des analyses sur les performances d'utilisation du Site).
`;

const sharePersonalInfoTitle = "PARTAGE DE VOS INFORMATIONS PERSONNELLES";
const sharePersonalInfo1 = `
  Nous partageons vost informations personnelles avec des services tierces pour nous aider au bon fonctionnement du 
  Site. Comme mentionné plus haut, nous utilisons Stripe ("Service de Paiement") pour gérer les abonnements de nos
  utilisateurs. Vous pouvez en apprendre plus sur la Politique de Confidentialité de Stripe ici: 
  https://stripe.com/fr-ca/legal. Nous utilisons également Google Analytics pour nous aider à comprendre comment nos
  utilisateurs se servent du Site. Vous pouvez en apprendre plus sur comment Google utilise vos Informations 
  Personnelles ici: https://www.google.com/intl/en/policies/privacy/. Vous pouvez également empêcher la prise 
  d'informations par Google Analytics ici: https://tools.google.com/dlpage/gaoptout.
`;
const sharePersonalInfo2 = `
  Finalement, il est possible que nous partageons vos Informations Personnelles pour être conforme à certaines lois 
  et régulations, pour un mandat d'arrêt, pour une requête judiciaire pour de l'information que nous recevons ou tout
  autre raison pour protéger nos droits.
`;

const changesTitle = "CHANGEMENTS";
const changes1 = `
  Il est possible que cette Politique de Confidentialité soit mise à jour de temps à autre pour refléter, par exemple,
  des changements dans nos pratiques ou pour d'autres raisons opérationnelles, légales ou régulatoires.
`;

const contactUsTitle = "NOUS CONTACTER";
const contactUs1 = `
  Pour plus d'informations à propos de nos pratiques de confidentialité, si vous avez des questions ou que vous voulez
  faire une plainte, veuillez, s'il-vous-plaît, nous contacter par e-mail à aide@kasba.app.
`;

export const policy = [
  title,
  intro,
  infoWeCollectTitle,
  infoWeCollect1,
  infoWeCollect2,
  infoWeCollect3,
  infoWeCollect4,
  usePersonalInfoTitle,
  usePersonalInfo1,
  usePersonalInfo2,
  sharePersonalInfoTitle,
  sharePersonalInfo1,
  sharePersonalInfo2,
  changesTitle,
  changes1,
  contactUsTitle,
  contactUs1
];
