import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useAxiosEffect } from "../../hooks/axios/action";
import { api } from "../../api";
import { login } from "../../states/user";
import { localStorage } from "../../utils";
import LoadingPage from "../common/LoadingPage";
import HomePage from "../HomePage";
import LoginPage from "../LoginPage";
import LoginModalPage from "../LoginModalPage";
import LegalPage from "../LegalPage";
import PrivacyPolicyPage from "../PrivacyPolicyPage";
import TermsOfServicesPage from "../TermsOfServicesPage";
import AppBar from "./AppBar";
import PrivateRoute from "./PrivateRoute";
import AccountPage from "../AccountPage";
import Footer from "./Footer";
import { PageBody } from "./App.styled";

export const App = () => {
  const { pathname } = useLocation();
  const onLoginPage = pathname === routes.login || pathname === routes.mobileLogin;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const refreshTokenQuery = useAxiosEffect({
    toRun: api.auth.refreshToken(localStorage.refreshToken.get("")),
    action: login
  });

  if (refreshTokenQuery.isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {!onLoginPage && <AppBar />}
      <PageBody>
        <Switch>
          <Route exact path={routes.home} component={HomePage} />
          <Route exact path={routes.login} component={LoginPage} />
          <Route exact path={routes.mobileLogin} component={LoginModalPage} />
          <Route exact path={routes.legal} component={LegalPage} />
          <Route exact path={routes.privacyPolicy} component={PrivacyPolicyPage} />
          <Route exact path={routes.termsOfServices} component={TermsOfServicesPage} />
          <PrivateRoute exact path={routes.account} component={AccountPage} />
          <Redirect to={routes.home} />
        </Switch>
      </PageBody>
      {!onLoginPage && <Footer />}
    </>
  );
};
