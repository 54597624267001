import React from "react";
import Grid from "@mui/material/Grid";
import QuoteItem from "./QuoteItem";

const mimiQuote = "L'application me permet de ne plus me soucier des fiches à remplir.";
const thomQuote = "Je trouve que c'est vraiment une super initiative!";

export const QuoteSection = () => {
  return (
    <Grid container justifyContent="center" sx={{ padding: "3rem 0" }}>
      <QuoteItem quote={mimiQuote} name={"Merieme Mrabet Kandri - RSG"} />
      <QuoteItem quote={thomQuote} name={"Thomas Bourque - Parent"} />
    </Grid>
  );
};
