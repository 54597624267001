import React from "react";
import { Route, Redirect } from "react-router-dom";
import type { RouteProps } from "react-router-dom";

import { routes } from "../../../routes";
import { useAppSelector } from "../../../hooks";

export const PrivateRoute = (props: RouteProps) => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  if (!isLoggedIn) return <Redirect to={routes.home} />;

  return <Route {...props} />;
};
