import { createLogger } from "redux-logger";
import { env } from "../utils";
import { reducer as user } from "../states/user";
import { reducer as subscription } from "../states/subscription";
import { configureStore } from "@reduxjs/toolkit";

const createMiddlewares = () => {
  if (env.isDev) {
    return [createLogger()];
  }

  return [];
};

export const store = configureStore({
  reducer: { user, subscription },
  middleware: createMiddlewares()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
