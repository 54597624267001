import React from "react";
import { Box } from "@mui/system";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import { routes } from "../../../routes";
import { useAppSelector, useAxiosQuery } from "../../../hooks";
import { logout } from "../../../states/user";
import { api } from "../../../api";
import KasbaLogo from "../../common/KasbaLogo";
import AnonymousMenu from "./AnonymousMenu";
import LoggedInMenu from "./LoggedInMenu";

export const AppBar = () => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  const logoutQuery = useAxiosQuery(logout);
  const onLogoutClick = () => {
    logoutQuery.run(api.auth.logout());
  };

  return (
    <MuiAppBar
      position="static"
      color="transparent"
      sx={{
        height: 80,
        boxShadow: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
      }}
    >
      <MuiToolbar
        sx={{
          justifyContent: "space-between",
          height: 80
        }}
      >
        <Box height="60%">
          <KasbaLogo to={routes.home} />
        </Box>
        {isLoggedIn && <LoggedInMenu onLogoutClick={onLogoutClick} />}
        {!isLoggedIn && <AnonymousMenu />}
      </MuiToolbar>
    </MuiAppBar>
  );
};
