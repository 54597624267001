import React from "react";
import Container from "@mui/material/Container";

export interface PageContainerProps {
  children: React.ReactChild | React.ReactChild[];
}

export const PageContainer = (props: PageContainerProps) => (
  <Container maxWidth="sm" sx={{ m: "6rem auto" }}>
    {props.children}
  </Container>
);
