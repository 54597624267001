import React from "react";
import { useHistory } from "react-router";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { routes } from "../../../routes";
import PageContainer from "../../common/PageContainer";
import ErrorCard from "../../common/ErrorCard";

export interface SubscriptionSucceededPageProps {
  error: boolean;
}

export const SubscriptionSucceededPage = (props: SubscriptionSucceededPageProps) => {
  const history = useHistory();
  const returnToAccountClick = () => {
    history.push(routes.account);
  };

  return (
    <PageContainer>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          <CheckIcon sx={{ fontSize: "8rem" }} color="success" />
        </Grid>
        <Grid item>
          <Box fontSize="1.5em" textAlign="center" fontWeight="300">
            Bienvenue à Kasba!
            {props.error && <ErrorCard message={errorMessage()} />}
          </Box>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={returnToAccountClick}>
            Retournez à votre compte
          </Button>
        </Grid>
      </Grid>
    </PageContainer>
  );

  function errorMessage() {
    return (
      "Votre abonnement est actif, mais une erreur inattendue s'est produite. " + "Veuillez contactez aide@kasba.app"
    );
  }
};
