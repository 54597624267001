import React from "react";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/system/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Settings from "@mui/icons-material/Settings";
import { logout } from "../../../states/user";
import { useAppSelector, useAxiosQuery } from "../../../hooks";
import { dateAsReadableText } from "../../../utils";
import { api } from "../../../api";
import { Card } from "../AccountPage.styled";

export const AccountCard = () => {
  const deleteAccountQuery = useAxiosQuery<void>(logout);
  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };
  const handleDeleteAccountClick = () => {
    setSettingsAnchorEl(null);
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);
  const handleConfirmAcccountDeletion = () => {
    deleteAccountQuery.run(api.account.delete());
  };

  const firstName = useAppSelector((s) => s.user.firstName);
  const lastName = useAppSelector((s) => s.user.lastName);
  const email = useAppSelector((s) => s.user.email);
  const memberSince = useAppSelector((s) => s.user.memberSince);

  return (
    <Card>
      <CardHeader
        title="Mon compte"
        action={
          <IconButton onClick={handleSettingsClick}>
            <Settings color="primary" />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          {renderMemberSince()}
          <Grid item>
            <div>
              <Box fontWeight="700" sx={{ mb: "0.2rem" }}>
                {email}
              </Box>
            </div>
            <div>
              {firstName} {lastName}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      {renderMenu()}
      {renderDeleteDialog()}
    </Card>
  );

  function renderMemberSince() {
    return (
      <Grid item>
        <Box fontSize="0.8rem" fontStyle="italic">
          Membre depuis le {dateAsReadableText(memberSince)}
        </Box>
      </Grid>
    );
  }

  function renderMenu() {
    return (
      <Menu anchorEl={settingsAnchorEl} open={Boolean(settingsAnchorEl)} onClose={() => setSettingsAnchorEl(null)}>
        <MenuItem onClick={handleDeleteAccountClick}>Supprimer mon compte</MenuItem>
      </Menu>
    );
  }

  function renderDeleteDialog() {
    return (
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>{"Voulez-vous réellement supprimer votre compte?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            En supprimant votre compte, votre abonnement sera automatiquement annulé et les données relatives à votre
            compte seront également supprimées.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Annuler</Button>
          <Button onClick={handleConfirmAcccountDeletion} color="error">
            Supprimer mon compte
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};
